.preloader {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #009e4f;
	margin: 0 8px;
	animation: fadeInOut 1s infinite ease-in-out;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.preloader:nth-child(2) {
	animation-delay: 0.25s;
}

.preloader:nth-child(3) {
	animation-delay: 0.5s;
}
.preloader-text {
	margin-top: 10px;
	font-size: 16px;
	color: #3a3a3a;
	animation: fadeInOut 1s infinite ease-in-out;
}
.circles-container {
	display: flex;
	align-items: center;
}
@keyframes fadeInOut {
	0%,
	100% {
		opacity: 0.2;
	}
	50% {
		opacity: 1;
	}
}
.preloader-wrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: transparent;
	justify-content: center;
	z-index: 10000;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.accordionHead {
	color: #ffffff;
	font-size: 24px;
	margin-bottom: 12px;
}
.rowJustifyCenter {
	display: flex;
	justify-content: center;
}
[aria-expanded="true"] .accordionIcon {
	transform: rotate(45deg) !important;
}
.rowAlignItemsCenter {
	align-items: stretch; /* This will ensure children take the full height */
}
.forceHeight {
	height: 100%;
}
.paddingem3 {
	padding-left: 3.2em;
	padding-right: 4.1em;
}

.landingContainer {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.lineHeight15 {
	line-height: 1.5;
}

.landingButtons {
	padding: 30px 20px;
	text-align: center;
	border: dashed 2px white;
	border-radius: 8%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;
}
.landingButtonsImg {
	height: 60px;
	margin-bottom: 10px;
}
.spanText {
	margin-top: 40px;
	font-size: 25px;
	color: #ffffff;
}
.accordionborder {
	border-top: 2px solid #009e4e;
}
.accordionborderlast {
	border-bottom: 2px solid #009e4e;
}
.accordionStyle {
	color: #000000;
}
.accordionIcon {
	color: #17a75f !important;
	font-weight: bold;
}
.landingTitle {
	color: #17a75f;
	font-size: 28px;
	text-align: left;
	font-weight: bold;
}
.landingTitleAr {
	color: #17a75f;
	font-size: 28px;
	text-align: right;
	font-weight: bold;
}
.manualTitle {
	color: #17a75f;
	font-size: 20px;
	text-align: left;
	font-weight: bold;
}
.manualTitleAr {
	color: #17a75f;
	font-size: 20px;
	text-align: right;
	font-weight: bold;
}
.manualPadding20 {
	padding: 0 20px;
}
.manualPadding40 {
	padding: 0 40px;
}
.manualPadding50 {
	padding: 0 50px;
}
.landingText {
	color: #ffffff;
}
.backgroundWhite {
	background-color: #ffffff;
}
.logosize {
	height: 75px;
	margin-top: 15px;
	text-align: center;
}
.logosizeFOOTER {
	max-height: 75px;
	max-width: 100%;
	text-align: center;
}
.textGreen {
	color: #17a75f !important;
}
.textWhite {
	color: #ffffff !important;
}
.colorBlack {
	color: #000000 !important;
}
.textBold {
	font-weight: bold;
}
.addPageHeight {
	min-height: calc(100vh - 300px);
}
.headerpadding {
	padding-top: 0;
}
.headerbackgound {
	background-color: #ffffff;
}
.loginButton {
	background-color: #17a75f;
	border: 0px;
	border-radius: 2px;
	color: #ffffff;
	padding: 2px 16px 2px 8px;
	margin: 8px;
	margin-top: 34px;
	font-size: 16px;
	height: fit-content;
	width: fit-content;
}
.loginButton3 {
	background-color: #17a75f;
	padding: 2px 16px 5px 16px;
	border: 0px;
	border-radius: 2px;
	color: #ffffff;
	margin: 8px;
	margin-top: 34px;
	font-size: 16px;
	height: fit-content;
	width: fit-content;
}
.loginButton2 {
	background-color: #17a75f;
	border: 0px;
	border-radius: 2px;
	color: #ffffff;
	padding: 2px 8px 2px 16px;
	margin: 8px;
	margin-top: 34px;
	font-size: 16px;
	height: fit-content;
	width: fit-content;
}
.verticalheight100 {
	height: 100vh;
}

.buttonDownload {
	background-color: transparent;
	border: 0px;
	border-radius: 2px;
	color: #000000;
	padding: 2px 16px 2px 8px;
	margin: 8px;
	margin-top: 22px;
	font-size: 16px;
	width: fit-content;
	align-content: center;
}
.buttonDownload2 {
	background-color: transparent;
	border: 0px;
	border-radius: 2px;
	color: #000000;
	padding: 2px 8px 2px 16px;
	margin: 8px;
	margin-top: 22px;
	font-size: 16px;
	width: fit-content;
	align-content: center;
}
.buttonDownload:hover,
.buttonDownload2:hover {
	text-decoration: none;
}
.buttonDownload svg,
.buttonDownload2 svg {
	margin-left: 8px;
	margin-right: 8px;
}

.loginButton svg,
.loginButton2 svg {
	margin-left: 8px;
	margin-right: 8px;
}
.textalignright {
	text-align: right !important;
}
.textalignleft {
	text-align: left !important;
}
.size18 {
	font-size: 18px !important;
}
.size25 {
	font-size: 25px;
}
.size30 {
	font-size: 30px;
}
.size50 {
	font-size: 50px;
}
.accordiontitle {
	font-size: 18px;
}
.sliderLanding > .slick-list > .slick-track {
	display: inline-flex;
	gap: 20px !important;
	padding: 20px 0;
	float: inline-start !important;
}
.sliderLanding .slick-list .slick-track {
	float: inline-start !important; /* Increase specificity */
}

.accordiontext {
	font-size: 15px;
	color: #000000;
	padding-bottom: 0.5rem;
}
.fixCarossell {
	margin-right: 0 !important;
}
.overlaychange .overlay {
	background: rgba(0, 0, 0, 0.5) !important;
}
.forceFlex {
	flex: 0;
}
.mt10 {
	margin-top: 10px;
}

.texAlignLeft {
	text-align: left;
}
.texAlignRight {
	text-align: right;
}
.paddingLfRg20 {
	padding-left: 20px !important;
	padding-right: 20px !important;
}
.forcefooterrow {
	--falcon-gutter-x: 0 !important;
	--falcon-gutter-y: 0 !important;
}
.languageDropDown {
	color: #17a75f !important;
	margin: 20px;
	margin-top: 30px;
	font-size: 16px;
}
.menuDropDown {
	color: #000000 !important;
	margin: 5px 10px;
	margin-top: 20px;
	font-weight: 300 !important;
}
.menuDropDown a,
.menuDropDown p {
	color: #000000 !important;
	font-weight: 300 !important;
	font-size: 16px;
}
.font300 {
	font-weight: 300 !important;
}
.menuDropDown .dropdown-menu:after {
	display: none !important;
}
.carousellButtons .carousel-control-next,
.carousellButtons .carousel-control-prev {
	display: none !important;
}
.carousellButtons {
	margin-top: 100px;
}
.textCenter {
	text-align: center !important;
}
.mainPageMenu {
	color: #000000 !important;
	margin: 20px;
	margin-top: 30px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.languageOptions {
	background: transparent;
	box-shadow: 0;
	border: 0;
	padding: 5px 0;
	font-size: 16px;
}
.languageOptions:hover {
	background: #ffffff !important;
	color: #17a75f !important;
}
.dropodownStyles {
	width: 100%;
	box-shadow: none !important;
	border: 0 !important;
	border-radius: 0 !important;
	left: 0 !important;
	right: 0 !important;
	padding-left: 0px !important;
	top: 86%;
}
.dropodownStyles:after {
	width: 100%;
	box-shadow: none !important;
	border: 0 !important;
	background-color: transparent !important;
}
.dropodownStyles:hover {
	background-color: #ffffff !important;
	color: #17a75f !important;
}

.dropodownStylesAr {
	width: 100%;
	box-shadow: none !important;
	border: 0 !important;
	border-radius: 0 !important;
	left: 0 !important;
	right: 13px !important;
	padding-right: 13px !important;
	top: 86% !important;
}
.dropodownStylesAr:after {
	width: 100%;
	box-shadow: none !important;
	border: 0 !important;
	background-color: transparent !important;
}
.dropodownStylesAr:hover {
	background-color: #ffffff !important;
	color: #17a75f !important;
}
.menuDropDown .dropodownStylesAr {
	right: -10px !important;
	padding-right: 0px !important;
	top: 100% !important;
}
.menuHover:hover {
	color: #17a75f !important;
	background-color: #ffffff !important;
}
.dropodownStylesAr .dropdown-item:hover {
	background-color: #ffffff !important;
}
.rowJustifyLeft {
	justify-content: left;
}
.rowJustifyRight {
	justify-content: right;
}
.iconColors {
	color: #17a75f !important;
	font-size: 80px;
}
.buttonsTitle {
	font-size: 18px;
	font-weight: bold;
}
.iconImages {
	padding: 30px;
	padding-top: 0px;
	height: 135px;
}
.menuShadow {
	box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.056);
}
.iconImagesMax {
	max-width: 100%;
	max-height: 110px;
}
.footerIcon {
	color: #ffffff;
	font-size: 30px !important;
	margin: 10px;
}
.buttonsSubTitle {
	color: #acacac;
	font-size: 16px;
	line-height: 1.2;
}
.padding35em {
	padding: 3.5em;
}
.whiteButton {
	color: #ffffff;
	background: transparent;
	border: 1px solid white;
	padding: 4px 18px;
	border-radius: 2px;
}
.whiteButton .fa-arrow-left {
	margin-right: 8px;
}
.whiteButton .fa-arrow-right {
	margin-left: 8px;
}
.carouselPartnersImage {
	max-height: 80px;
	max-width: 100%;
	margin: auto;
}
.carouselImage {
	height: 200px;
	background-size: cover;
}
.carouselcontainer {
	padding: "10px";
	border-radius: 20px;
	overflow: hidden;
	box-shadow: 2px 2px 7px #00000024;
}
.backgroundStyles{
	background-size: cover;
	background-position: top;
	display: flex; /* Added to allow justify-content */
	justify-content: center;
}
.carouselPartnercontainer {
	height: 130px;
	align-content: center;
	padding: 10px;
	text-align: -webkit-center;
	text-align: center;padding: "20px";
	margin: "10px"
}
.carouselTitle {
	padding: "20px";
	color: #000000;
}
.carouselTitleAr {
	padding: 20px;
	color: #000000;
	direction: rtl;
}
.backgroundNavy {
	background-color: #0d3747;
}
.carouselDateAr {
	background: #f2f2f2;
	padding: 20px;
	direction: rtl;
}
.carouselDateAr svg {
	margin-left: 5px;
}
.carouselDate svg {
	margin-right: 5px;
}
.carouselDate {
	background: #f2f2f2;
	padding: 20px;
}
.backgroundWhite {
	background-color: #ffffff;
}
.sliderLanding > .slick-dots > li.slick-active > button:before {
	color: #17a75f !important;
	font-size: 20px;
}
.sliderLanding .slick-dots li.slick-active button:before {
	color: #17a75f !important; /* Set color for active dot */
	font-size: 20px !important; /* Ensure font size is changed */
}

.dotsLanding li button:before {
	color: #17a75f !important; /* Set color for inactive dots */
	font-size: 16px !important;
}
.footerLink {
	color: #ffffff !important;
	text-decoration: none !important;
}
.footerLink:hover {
	color: #ffffff !important;
	text-decoration: none !important;
}
.marginTop100 {
	margin-top: 100px;
}
.downloading {
	font-size: 20px;
	padding: 0 0 7px 0;
	font-weight: bold;
}
.downloadingText {
	font-size: 18px !important;
	padding: 0 15px;
}
.downloadingText:hover {
	padding: 0 15px;
}
.downloadinglink:hover {
	text-decoration: none;
	color: #17a75f !important;
}
.downloadingButton {
	font-size: 18px !important;
}
@media only screen and (max-width: 600px) {
	.texAlignCenter {
		text-align: center !important;
	}
	.loginButton {
		margin-top: 15px;
	}
	.loginButton2 {
		margin-top: 15px;
		margin-right: 0;
		margin-left: 0;
		padding: 0;
	}
	.loginButton3 {
		margin-top: 15px;
	}
	.buttonDownload {
		margin-top: 15px;
		margin-right: 0;
		margin-left: 0;
		padding: 0;
	}
	.buttonDownload2 {
		margin-top: 15px;
		margin-right: 0;
		margin-left: 0;
		padding: 0;
	}
}

.gradiantTitle {
	background: linear-gradient(90deg, rgba(0, 158, 78, 0.9304971988795518) 0%, rgba(13, 55, 71, 1) 59%);
	border-radius: 0 !important;
}
.gradiantTitleAr {
	background: linear-gradient(90deg, rgba(13, 55, 71, 1) 37%, rgba(0, 158, 78, 0.9304971988795518) 100%);
	border-radius: 0 !important;
}
.gradiantTitle h5,
.gradiantTitleAr h5 {
	color: #ffffff !important;
	font-weight: bold;
}
.forceBackgroundwhite {
	background-color: #ffffff !important;
}
.forceBackgrounddark {
	background-color: #121e2d !important;
}
.forceButtonColorwhite {
	background-color: #00a862 !important;
	border-color: #00a862 !important;
	color: #ffffff !important;
}
.forceButtonColorwhite:hover {
	background-color: #00804b !important;
	border-color: #00804b !important;
}
.tableHeader thead {
	background-color: #1a3745 !important;
	color: #ffffff !important;
}
.navCapitilize {
	text-transform: uppercase !important;
}
.navBold {
	font-weight: bold !important;
}
.footerminus60 {
	bottom: -60px !important;
}
.footerplacement {
	position: relative;
	margin-top: 20px;
	margin-bottom: -3.9875rem;
}
@media (min-width: 576px) {
	.footerplacement {
		margin-bottom: -3.9875rem;
	}
}
.tableHeader thead th {
	color: #ffffff !important;
	border-color: #019e504d;
}
.tableHeader tr:nth-of-type(2n) > * {
	background-color: #f5fbf9 !important;
	--falcon-table-accent-bg: #f5fbf9 !important;
}
.tableHeader tr > * {
	color: #000000 !important;
}
.forceBackgrounddark .tableHeader {
	color: #f5fbf9 !important;
}
.forceBackgrounddark .tableHeader tr:nth-of-type(2n) > * {
	background-color: #121e2d !important;
	--falcon-table-accent-bg: #0b1727 !important;
}
.forcefooterrow {
	--falcon-gutter-x: 0 !important;
	--falcon-gutter-y: 0 !important;
}
.menuuser.avatar .avatar-name {
	background-color: rgb(0, 168, 98) !important;
}
.menuuser .backgroundGreen {
	background-color: rgb(0, 168, 98) !important;
}

.backgroundGreen {
	background-color: rgb(0, 168, 98) !important;
}
.menuuser svg {
	width: 100%;
	font-size: 17px;
	padding-top: 6px;
}
.justifyLeft {
	justify-content: left !important;
}

.justifyRight {
	justify-content: right !important;
}
.textAlignCenter {
	text-align: center;
}
.BodyBorder {
	border-radius: 0 !important;
	box-shadow: none !important;
	border: 1px solid rgb(0, 168, 98) !important;
	margin-bottom: 80px;
}
.filterFieldBoxes {
	border: 1px solid rgb(0, 168, 98) !important;
	border-radius: 0 !important;
}
.filterFieldBoxes button,
.filterFieldBoxes input {
	border-radius: 0px !important;
	margin-left: 0px !important;
}
.tableButton {
}
.navLabelgreen {
	color: rgb(0, 168, 98) !important;
}
.hrColor {
	color: rgb(0, 168, 98) !important;
}
.greenLineOverwright {
	background-color: rgb(0, 168, 98) !important;
}
.greenLineOverwright:before {
	background-color: rgb(0, 168, 98) !important;
	width: 90% !important;
}
.greenLineOverwright:after {
	background-color: rgb(0, 168, 98) !important;
	width: 80% !important;
}
.marginTop25 {
	margin-top: 25px !important;
}

[dir="rtl"] .mb-3 label.form-label {
	float: right;
}

[dir="rtl"] .navbar-nav-icons {
	margin-left: 0 !important;
}

[dir="rtl"] .navbar-nav-icons .theme-control-toggle {
	padding-bottom: 6px !important;
	padding: 0 !important;
}

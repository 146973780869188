@import "~bootstrap/dist/css/bootstrap.css";
@import "font-awesome/css/font-awesome.min.css";
/*.form-list {
    margin: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }*/

.form-name {
	font-size: 18px;
	float: left;
}
.tool-header {
	display: flex;
	justify-content: flex-end;
	text-align: right;
	margin: 20px;
}
.action {
	display: flex;
	justify-content: flex-end;
	margin: 5px 5px 5px 5px;
}
.action:hover {
	background-color: #d8d8d8;
}

.add-button,
.edit-button,
.delete-button,
.start-process-button,
.upload-button,
.view-diagram-button,
.edit-diagram-button {
	/* padding: 5px 10px; */
	border: 0;
	border-radius: 5px;
	cursor: pointer;
	float: right;
}

.add-button {
	background-color: #007bff;
	color: #fff;
	margin-right: 10px;
	margin-bottom: 10px;
	border-radius: 100%;
}
.upload-button {
	background-color: #edf2f9;
	color: #007bff;
	margin-right: 10px;
	margin-bottom: 10px;
	border-radius: 100%;
}
.edit-button {
	background-color: #007bff;
	color: #fff;
	margin-right: 10px;
}

.delete-button {
	background-color: #b9b9b9;
	color: #000000;
}
.add-button {
	background-color: #edf2f9;
	color: #007bff;
	margin-right: 10px;
	margin-bottom: 10px;
}

.start-process-button {
	background-color: #007bff;
	color: #fff;
	margin-right: 10px;
	margin-left: auto;
}

.close-icon {
	border: 1px solid transparent;
	background-color: transparent;
	display: inline-block;
	vertical-align: middle;
	outline: 0;
	cursor: pointer;
}
.close-icon::after {
	content: "X";
	/* display: block; */
	/* width: 15px;
    height: 15px; */
	/* position: absolute; */
	background-color: #d8d8d8;
	/* z-index:1; */
	/* right: 35px; */
	/* top: 0;
    bottom: 0; */
	/* margin: auto; */
	padding: 0.1em 0.4em;
	border-radius: 100%;
	text-align: center;
	/* color: white; */
	font-weight: normal;
	font-size: 12px;
	/* box-shadow: 0 0 2px #E50F0F; */
	cursor: pointer;
	vertical-align: text-top;
}

/* #display-file{
    display: inline-block;
    margin: 5px auto;
    position: relative;
  } 
  #display-file:empty ~ .close-icon{
    display: none;
  }
  #file-input:empty ~ .close-icon{
    display: none;
  } */

.view-diagram-button {
	background-color: #b9b9b9;
	color: #000000;
	margin-right: 10px;
}

.edit-diagram-button {
	background-color: #b9b9b9;
	color: #000000;
	margin-right: 10px;
}
.fa.fa-plus-circle,
.fa.fa-upload,
.fa.fa-file {
	font-size: 12px;
	margin-right: 10px;
}
.addNewButton {
	text-align: right;
	margin: 20px;
}
.custom-file-upload {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 0.1875rem 0.75rem;
	cursor: pointer;
	color: #4d5969;
	text-align: center;
	border-radius: 0.25rem;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1.5;
	background-color: #fff;
	margin: auto 0.25rem;
}
/* Button {
  color: red;
} */
.line-hover:hover {
	background-color: #e7e7e7;
	box-shadow: inset 0 0 0 0px var(--falcon-table-accent-bg);
}

.line-hover:hover td {
	background-color: #e7e7e7;
	box-shadow: inset 0 0 0 0px var(--falcon-table-accent-bg);
}
.icon-position {
	display: inline;
}
.play-hover:hover {
	background-color: rgb(0, 168, 98);
	border-color: rgb(0, 168, 98);
	color: #ffffff !important;
}
.botton-hover {
	background-color: #f1f1f1 !important;
	border-color: #f1f1f1 !important;
}
.botton-hover:hover {
	background-color: #ffffff !important;
	border-color: #ffffff !important;
}
.botton-circle {
	border-radius: 50% !important;
}
.custom-file-upload2 i.fa.fa-file {
	font-size: 40px;
	padding: 0 5px;
	color: #848484 !important;
}
#display-file {
	display: inline;
}
.fs--5 {
	font-size: 20px;
	vertical-align: middle;
}

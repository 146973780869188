@import "~bootstrap/dist/css/bootstrap.css";
@import "~formiojs/dist/formio.full.min.css";

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@font-face {
	font-family: "Cairo-Regular"; /*Can be any text*/
	src: local("Cairo-Regular"), url("./font/Cairo-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Cairo-Bold"; /*Can be any text*/
	src: local("Cairo-Bold"), url("./font/Cairo-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "Cairo-SemiBold"; /*Can be any text*/
	src: local("Cairo-SemiBold"), url("./font/Cairo-SemiBold.ttf") format("truetype");
}

.navbar-vertical .toggle-icon-wrapper {
	padding: var(--falcon-navbar-padding-y) var(--falcon-navbar-padding-x);
}
@media (min-width: 1200px) {
	.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl + .content .navbar-top {
		padding-right: 0 !important;
	}
}

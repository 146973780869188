#group-container-basic,
#group-container-advanced,
#group-container-layout,
#group-container-premium,
#group-container-data {
	display: grid;
	gap: 0.1rem;
}

.custom-panel-title .card .card-header.bg-default {
	background-color: #009E4F;
	color: white;
	font-family: "Cairo-Regular";

}

.form-group.remove-calendar-icon .input-group-append {
	display: none;
}

.builder-group-button {
	width: 100%;
}

.badge {
	--bs-badge-color: black !important;
}

.formio-wizard-nav-container li {
	border: none !important;
}

.edit-button-form,
a.edit-button-form {
	display: block;
	width: 100%;
	/*padding: var(--falcon-dropdown-item-padding-y) var(--falcon-dropdown-item-padding-x);*/
	clear: both;
	font-weight: 400;
	color: #344050 !important;
	text-align: inherit;
	white-space: nowrap;
	background-color: initial;
	border: 0;
}

li.page-item {
	border: none !important;
	z-index: 5;
}

.builder-sidebar,
.builder-sidebar_scroll {
	position: sticky;
	top: 15px;
	width: 140px !important;
}

.form-builder-group-header {
	padding: 0 !important;
}

.btn.btn-outline-secondary {
	display: block;
	width: 200px;
	float: right;
}

.text-600.btn-reveal.dropdown-toggle.btn.btn-link.btn-sm.btn-display {
	border-radius: 5px !important;
	border-style: solid !important;
	height: 36px;
	/*border-color: black !important;*/
	--falcon-btn-color: #748194;
	--falcon-btn-border-color: #748194;
	--falcon-btn-hover-color: #fff;
	--falcon-btn-hover-bg: #748194;
	--falcon-btn-hover-border-color: #748194;
	--falcon-btn-focus-shadow-rgb: 116, 129, 148;
	--falcon-btn-active-color: #fff;
	--falcon-btn-active-bg: #748194;
	--falcon-btn-active-border-color: #748194;
	--falcon-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--falcon-btn-disabled-color: #748194;
	--falcon-btn-disabled-bg: transparent;
	--falcon-btn-disabled-border-color: #748194;
	--falcon-gradient: none;
}

.formbuilder {
	width: 100%;
	padding-top: 15px;
}

.breadcrumb {
	background-color: #2362b7 !important;
}

.formio-form nav ul li button {
	position: relative;
	margin: 30px;
	/* Adjust the margin value to set the desired spacing */
}

.formio-form nav {
	display: flex;
	width: 100%;
	position: relative;
	justify-content: center;
}

.formio-form nav ul li button.page-link {
	display: inline-block;
	position: relative;
	height: 25px;
	line-height: 25px;
	padding: 0 20px;
	border: 1px solid #ccc;
	margin-right: 2px;
	background-color: white;
}

.formio-form nav ul li button.page-link:after {
	content: "";
	border-top: 1px solid #ccc;
	border-right: 1px solid #ccc;
	width: 18px;
	height: 18px;
	position: absolute;
	right: 0;
	top: -1px;
	background-color: white;
	z-index: 150;

	-webkit-transform: translate(10px, 4px) rotate(45deg);
	-moz-transform: translate(10px, 4px) rotate(45deg);
	-ms-transform: translate(10px, 4px) rotate(45deg);
	-o-transform: translate(10px, 4px) rotate(20deg);
	transform: translate(10px, 4px) rotate(45deg);
}

.page-item:last-child .page-link::after {
	opacity: 0;
}

.formio-form nav ul li button.page-link:before {
	content: "";
	border-top: 1px solid #ccc;
	border-right: 1px solid #ccc;
	width: 18px;
	height: 18px;
	position: absolute;
	left: 0;
	top: -1px;
	background-color: white;
	z-index: 50;

	-webkit-transform: translate(-10px, 4px) rotate(45deg);
	-moz-transform: translate(-10px, 4px) rotate(45deg);
	-ms-transform: translate(-10px, 4px) rotate(45deg);
	-o-transform: translate(-10px, 4px) rotate(20deg);
	transform: translate(-10px, 4px) rotate(45deg);
}

.page-item:first-child .page-link::before {
	opacity: 0;
}

.formio-form>div>nav>ul.pagination .page-link::-webkit-scrollbar {
	display: none;
}

.formio-form nav ul li button.page-link {
	position: relative;
}

.wizard-page {
	padding-bottom: 20px;
}

.wizard-page .formio-component-htmlelement {
	margin-bottom: 35px;
}

.editgrid-listgroup.list-group {
	margin-bottom: 10px;
}

@keyframes colorAnimation {
	0% {
		background-color: #009e4f;
	}

	25% {
		background-color: #009e4f;
	}

	50% {
		background-color: #009e4f;
	}

	75% {
		background-color: #009e4f;
	}

	100% {
		background-color: #009e4f;
	}
}

.formio-form nav ul li.active button.page-link {
	animation: colorAnimation 2s infinite;
}

.formio-form>div>nav>ul.pagination .page-item.active button.page-link {
	color: #fff;
	background-color: #009e4f;
	border-color: #009e4f;
}

.formio-form .wizard-page .formio-component-htmlelement:first-child h2 {
	color: #009e4f;
	font-family: "cairo-regular";
}

.formio-form nav {
	display: none;
}

.formio-component-undefined {
	display: none;
}

.searchable-dropdown {
	position: relative;
}

.searchable-dropdown select {
	width: 100%;
	height: 35px;
}

.searchable-dropdown input {
	position: absolute;
	width: calc(100% - 20px);
}

.custom-dropdown option {
	width: auto;
}

.form_choices_list {
	right: 0px;
	z-index: 100;
	position: absolute;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ddd;
	top: 100%;
	margin-top: -1px;
	border-bottom-left-radius: 2.5px;
	border-bottom-right-radius: 2.5px;
	overflow: hidden;
	word-break: break-all;
	will-change: visibility;
}

.form_choices_input {
	display: block;
	width: 100%;
	padding: 5px;
	border: 1px solid #ddd;
	background-color: #fff;
	margin: 0;
}

.form_choices_input::placeholder {
	font-size: 14px;
}

.form_choices_list {
	/* position: relative; */
	max-height: 300px;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	will-change: scroll-position;
	margin: 0;
	padding-left: 0;
	list-style: none;
}

.form_choices_item {
	/* white-space: nowrap; */
	overflow: hidden;
	padding-right: 25px;
	text-overflow: ellipsis;
	position: relative;
	padding: 10px;
	font-size: 14px;
	cursor: pointer;
}

.form_main_select span {
	white-space: nowrap;
	overflow: hidden;
	padding-right: 25px;
	text-overflow: ellipsis;
}

.form-control.form_main_select {
	display: flex;
	cursor: pointer;
	position: relative;
}

.form_choices_button {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
	padding: 0px;
	background-repeat: no-repeat;
	background-size: 8px;
	background-position: left;
	/* position: absolute; */
	top: 75%;
	right: -16px;
	margin-top: 3px;
	/* margin-right: 25px; */
	height: 20px;
	width: 8px;
	border-radius: 10em;
	opacity: 0.5;
}

.form_main_select svg {
	width: 10px;
	height: 10px;
}

.form-svg-rotated {
	transform: rotate(180deg);
}

.editgrid-listgroup .col-sm-3 {
	display: flex;
	overflow: hidden;
	white-space: nowrap;
}

.formio-form .input-group-text {
	background-color: transparent;
	border: none;
}

.formio-form .warning-title {
	margin-top: 10px;
	background-color: #009e4f;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.formio-form .warning-title div {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.formio-form .report-form-title {
	margin-top: 10px;
	background-color: #009e4f;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.formio-form .report-form-title div {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.formio-form .seizure-report-during-visit-options {
	margin-top: 5px;
}

.formio-form .seizure-report-during-visit-options .form-radio {
	display: flex;
	flex-direction: column;
}

.formio-form .seizure-report-text,
.formio-form .seizure-report-input-text2 {
	margin-top: 5px;
	font-weight: bold;
}

.formio-form .seizure-report-input-text .input-group-text,
.formio-form .seizure-report-input-text2 .input-group-text {
	font-weight: bold;
}

.choices__list.choices__list--dropdown .choices__item.choices__item--selectable {
	text-overflow: unset;
	white-space: unset;
	padding-left: 5px;
	padding-right: 5px;
}

.wizard-page .Or_label .formio-component-htmlelement {
	margin-bottom: 0px;
	margin-top: 10px;
}

.violatedPerson2 .field-wrapper .field-content .form-radio div label {
	display: flex;
	justify-content: end;

	/* Reverse the order */
}

.violatedPerson2 .field-wrapper .field-content .form-radio .checkbox.form-check {
	justify-content: end;
	/* Reverse the order */
}

.violatedPerson2 .form-radio div label span {
	margin-right: 30px;
}

.violatedPerson2 .field-wrapper {
	flex-direction: column;
	justify-content: end;
}

.editgrid-actions {
	margin-top: 10px;
}

.leaflet-custom-map .leaflet-pane {
	position: relative;
	right: auto;
}

.leaflet-custom-map .leaflet-pane,
.leaflet-custom-map .leaflet-tile,
.leaflet-custom-map .leaflet-marker-icon,
.leaflet-custom-map .leaflet-marker-shadow,
.leaflet-custom-map .leaflet-tile-container,
.leaflet-custom-map .leaflet-pane>svg,
.leaflet-custom-map .leaflet-pane>canvas,
.leaflet-custom-map .leaflet-zoom-box,
.leaflet-custom-map .leaflet-image-layer,
.leaflet-custom-map .leaflet-layer {
	right: auto;

}

.cdw-select-box label {
	font-family: "Cairo-Bold";
	padding: 0;
}

.cdw-select-box span {
	font-family: "Cairo-Regular";
}

/* mobile */
@media screen and (max-width: 768px) {

	.custom-input-background input[type="text"] {
		background-color: transparent !important;
		border: none;
		box-shadow: none;
		font-family: "Cairo-Regular";
		font-size: 16px;
		/* width: max-content; */
		padding: 0 0 0 0;
		display: flex; 
		margin: 5px 0 5px 0;
	}

	.custom-input-background label {
		font-family: "Cairo-Bold";
		font-size: 16px;
		/* width: max-content; */
		padding: 0 0 0 0;
		display:flex; 
		outline: none; 
		margin: 5px 5px 5px 5px;
	}

	.custom-input-background div.field-wrapper{
		flex-direction: column; 
	}

	.custom-input-background label {	
		margin: 0 0 0 0;
	}

	.remove-background div {
		flex-direction: column; 
		background-color: transparent !important;
		border: none;
		box-shadow: none;
		font-family: "Cairo-Regular";
		padding: 0 0 0 0;
	}
	.remove-background label {
		font-family: "Cairo-Bold";
		font-size: 16px;
		/* width: max-content; */
		padding: 0 0 0 0;
		/* margin: 0 5px 0 5px; */
	}


	.companyCustomComp_textarea {
		/* flex:1; */
		background-color: transparent ;
		width: 100% ;
		border: none ;
		font-family: "Cairo-Regular";
		padding: 0 10px 0 0 ;
		resize: none;
		overflow: hidden;
	}
}

/* tablet */
@media screen and (min-width: 769px) and (max-width: 1715px) {

	.custom-input-background input[type="text"] {
		background-color: transparent !important;
		border: none;
		box-shadow: none;
		font-family: "Cairo-Regular";
		font-size: 16px;
		/* width: max-content; */
		padding: 0 0 0 0;
		display: flex; 
		margin: 5px 0 5px 0;
	}

	.custom-input-background label {
		font-family: "Cairo-Bold";
		font-size: 16px;
		/* width: max-content; */
		padding: 0 0 0 0;
		display:flex; 
		outline: none; 
		margin: 5px 5px 5px 5px;
	}

	.custom-input-background div.field-wrapper{
		flex-direction: column; 
	}

	.custom-input-background label {	
		margin: 0 0 0 0;
	}

	.remove-background div {
		flex-direction: column; 
		background-color: transparent !important;
		border: none;
		box-shadow: none;
		font-family: "Cairo-Regular";
		padding: 0 0 0 0;
	}

	.remove-background label {
		font-family: "Cairo-Bold";
		font-size: 16px;
		/* width: max-content; */
		padding: 0 0 0 0;
		/* margin: 0 5px 0 5px; */
	}


	.companyCustomComp_textarea {
		background-color: transparent ;
		width: 100% ;
		border: none ;
		font-family: "Cairo-Regular";
		padding: 0 10px 0 0 ;
		resize: none;
		overflow: hidden;
	}
}

/* desktop */
@media screen and (min-width: 1716px) {
	.barcode-margin{
		margin-left: 5px;
		margin-right: 5px;
	}

	.custom-input-background input[type="text"] {
		background-color: transparent !important;
		border: none;
		box-shadow: none;
		font-family: "Cairo-Regular";
		font-size: 16px;
		width: max-content;
		padding: 0 0 0 0;
		display: flex; 
		margin: 5px 0 5px 5px;
	}
	.custom-input-background label {
		font-family: "Cairo-Bold";
		font-size: 16px;
		width: max-content;
		padding: 0 0 0 0;
		display:flex; 
		outline: none; 
		margin: 5px 5px 5px 5px;
	}
	
	.custom-input-background div.field-wrapper{
		display: flex !important;
	}
	
	.remove-background div.field-wrapper {
		height: auto;
	}
	
	.remove-background div {
		background-color: transparent !important;
		border: none;
		box-shadow: none;
		font-family: "Cairo-Regular";
		padding: 0 0 0 0;
	}
	.remove-background label {
		font-family: "Cairo-Bold";
		font-size: 16px;
		width: max-content;
		padding: 0 0 0 0;
		margin: 0 5px 0 5px;
	}
		
	.remove-background-committee  {
		height: auto;
		margin-bottom: 5px;
		
	}
	
	.remove-background-committee div {
		background-color: transparent !important;
		
		font-family: "Cairo-Regular";
		
	}
	.remove-background-committee label {
		font-family: "Cairo-Regular";
		font-size: 16px;
		width: max-content;
		
		
	}
	.marginBottom  {
		
		margin-bottom: 30px;
		
	}	


	.companyCustomComp_custom-group{
		display: flex;
        align-items: baseline;
		/* margin-bottom: 10px; */
	}
	.companyCustomComp_label {
		margin-right: 10px;
	}

	.companyCustomComp_textarea {
		flex:1;
		background-color: transparent ;
		width: max-content ;
		border: none ;
		font-family: "Cairo-Regular";
		padding: 0 0 0 0 ;
		resize: none;
		overflow: hidden;
	}

	
}



.image-container {
	width: 150px;
	/* Set the width of the image container */
	height: 0 150px;
	/* Set the height of the image container */
	margin-bottom: 10px;
	
}

.image-container img {
    max-width: calc(100% - 10px); /* Adjusting the max-width to account for padding */
    max-height: calc(100% - 10px); /* Adjusting the max-height to account for padding */
    cursor: pointer;
    padding: 5px;
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    margin-right: 10px; /* Margin between images */
	margin-left: 5px;
}

/* To remove margin from the last image */
.image-container img:last-child {
    margin-right: 0;

}

.image-container.selected {
	border: 1.5px solid #009E4F; /* Customize border style and color */
	}

.custom-radio-group {
	display: flex;
	/*flex-wrap: wrap;
	justify-content: right;
	/* Center the items horizontally 
	align-items: center;
	/* Center the items vertically */
}

.radio-option {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	margin-top:5px;
	/*margin-right: 20px;*/
}


.radio-container {
	margin-bottom: 5px;
	margin-top: 5px
}

.label-container {
	text-align: center;
	margin-bottom: 10px;
}

.radio-label label {
    font-family: "Cairo-Bold";
    font-size: 16px;
    width: max-content;
    padding: 0 0 0 0;
   
}
.dynamic-table tbody tr:not(:first-child):nth-child(even) {   background-color: white; } 
.dynamic-table tbody tr:nth-child(odd) {   background-color: rgb(236, 234, 234); }
.dynamic-table th {   border: 0.2px solid #bbb7b7;   padding: 8px;}
.dynamic-table td {   border: 0.2px solid #bbb7b7;   padding: 8px;}
.dynamic-table {width: '100%';}

.error-input {
    border: 1px solid red;
}


.fileUploadTooltip ul.list-group li.list-group-item a[ref="fileLink"] {
	color: #2362b7;
	text-decoration: underline;
	font-weight: bold;
	position: relative;
	cursor: pointer;
	/* Required for positioning the tooltip */
}

.fileUploadTooltip ul.list-group li.list-group-item a[ref="fileLink"]::after {
	content: 'Click to download the file';
	/* Tooltip text */
	position: absolute;
	bottom: 100%;
	/* Position above the link */
	left: 50%;
	transform: translateX(-50%);
	background-color: #333;
	/* Tooltip background color */
	color: #fff;
	/* Tooltip text color */
	padding: 5px 10px;
	/* Tooltip padding */
	border-radius: 5px;
	/* Rounded corners */
	white-space: nowrap;
	/* Prevent text wrapping */
	font-size: 12px;
	/* Tooltip font size */
	z-index: 999;
	/* Make sure it appears above other content */
	opacity: 0;
	/* Initially hidden */
	visibility: hidden;
	/* Initially hidden */
	transition: opacity 0.3s;
	/* Smooth transition for appearance */
}

.fileUploadTooltip ul.list-group li.list-group-item a[ref="fileLink"]:hover::after {
	opacity: 1;
	/* Show tooltip on hover */
	visibility: visible;
	/* Make it visible on hover */
}
.remove-Border div textarea{
	border: none;
	box-shadow: none;
}
.errorMessageStyle div textarea{
	color: red;
	font-weight: bold;
}
.center-button{
	margin-top: 10px;
}
.align-button-column {

	margin-top: 18px;
  }

  
.panelDisplay{
	display: none;
}

.centerPanelBox{
	min-height: 500px;
    align-content: center;
	z-index: 1;

}
.clearpadding .col-md-2{
	padding: 0.5px !important;
}

.rigthposition{
	direction: rtl;
	padding-right: 0;
	margin-top: 5px;
}

.leftposition{
	direction: ltr;
	padding-left: 0;
	margin-top: 5px;
}

.centerPanelBox .bg-light {
	background-color: transparent !important;
}
.centerPanelBox .card {
	box-shadow: none !important;
}
.nonebackground{
	box-shadow: none;
	background-color: transparent;
}
.nonebackground input[type="text"]{
	box-shadow: none;
	background-color: transparent;
	border: none;
}
.errorType input[type="text"]{
	font-weight: bold;
	color: red;
}

.changeDirection{
	direction: rtl !important;
}
.changeDirection .choices.form-group.formio-choices {
    direction: rtl;
    text-align: right; 
}

.changeDirection .choices__input,
.changeDirection .choices__list--dropdown {
    direction: rtl;
}


.paneltoUp {
	position: absolute;
	animation-name: panelsDown;
	animation-duration: 1s;
	animation-fill-mode: forwards;
	width: 100%;
}

.appearancePanel {
	position: relative;
	animation-name: appearanceDown;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	opacity: 0; /* Start hidden */
	width: 100%;
}
.paneltoDown {
	position: absolute;
	animation-name: panels;
	animation-duration: 1s;
	animation-fill-mode: forwards;
	width: 100%;
}
.appearancePanelDown {
	position: relative;
	animation-name: appearance;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	opacity: 0; /* Start hidden */
	width: 100%;
}

@keyframes appearance {
	from {
		top: -200px;
		opacity: 0; /* Start hidden */
	}
	to {
		top: 0;
	
		opacity: 1; /* Become fully visible */
	}
}
@keyframes appearanceDown {
	from {
		bottom: -200px;
		opacity: 0; /* Start hidden */
	}
	to {
		bottom: 0px;
	
		opacity: 1; /* Become fully visible */
	}
}
@keyframes panels {
	from {
		top: 0px;
		opacity: 0.6;
	}
	to {
		top: 200px;
		display: none;
		opacity: 0; /* Gradually become less visible */
	}
}

@keyframes panelsDown {
	from {
		bottom: 75px;
		opacity: 1;
	}
	to {	
		bottom: 200px;
		display: none;
		opacity: 0; /* Gradually become less visible */
	}
}

  
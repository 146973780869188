.dark .customTable tr td {
	color: #9097a1 !important;
}

.containerfull {
	max-width: 100% !important;
}

.customTable {
	width: 100%;
}

.loginAlert {
	color: red;
	font-size: 13px;
	padding: 18px 0 0 0;
	text-align: center;
}

.addNewButton {
	text-align: right;
	margin: 20px;
}

.loaderContainer {
	/*position: absolute;*/
	text-align: center;
	width: 100%;
	left: 0;
	top: 100%;
}

.dark td {
	color: #9097a1 !important;
}
.dark .line-hover:hover td {
	background-color: #000000;
}
.dark .col-form-label {
	color: var(--falcon-headings-color);
}
.dark label {
	color: #9da9bb !important;
}
.dark .signature-pad-canvas {
	mix-blend-mode: soft-light;
}
.dark .formio-component-file .fileSelector {
	color: #9da9bb !important;
}
.form-label.space {
	margin-left: 5px;
}
.dark .form_choices_item.darkselected {
	background-color: rgb(11 23 39) !important;
}
.dark .form_choices_list {
	background-color: #232e3c;
}
.dark .form_choices_input {
	background-color: #232e3c;
}
.dark .is-open .choices__list--dropdown {
	background-color: #232e3c;
	color: var(--falcon-input-color);
}
.dark .choices[data-type*="select-one"] .choices__input {
	background-color: #232e3c;
	color: var(--falcon-input-color);
}
.dark .choices__list--dropdown .choices__item--selectable.is-highlighted {
	background-color: rgb(11 23 39);
	color: var(--falcon-input-color);
}
.darklogo {
	display: none;
}
.dark .darklogo {
	display: block;
}
.dark .lightlogo {
	display: none;
}
.dark .formio-component-htmlelement p {
	color: #9da9bb !important;
}
.dark .formio-dialog.formio-dialog-theme-default .formio-dialog-content {
	background: #121e2d;
}
html[dir="rtl"]
	.formio-component-datetime
	.input-group
	> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	direction: ltr;
	text-align: right;
}
.avatar img {
	margin-top: 1px;
}
.form-check.checkbox {
	margin-top: 0.34375rem;
}
.formio-component-signature {
	margin-top: 15px;
}
.formio-component.formio-component-textfield .input-group {
	margin-bottom: 10px;
}
.form-check.noPadding {
	padding: 0;
	margin-top: 25px;
}
.formio-component.formio-component-panel {
	margin-top: 8px;
}
.disbaled {
	background-color: red;
	color: #fff;
	padding: 3px;
	border-radius: 10px;
	margin-left: 5px;
	font-size: 10px;
}
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #2c7be5;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}
.dark tr th {
	color: #9da9bb !important;
}
.dark .card-body label {
	color: #9da9bb !important;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}
}

.popupAlert {
	display: inline-block;
	width: 100%;
	text-align: right;
}

.successMessage {
	color: green;
	font-size: 13px;
}

.createAccount {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin-top: 15px;
}

.createAccount p {
	font-size: 15px !important;
}

.displayInlineBlock {
	display: inline-block;
}

.inlineError {
	display: inline-block;
	float: right;
	width: 50%;
	padding-top: 5px;
}

.clientLogo {
	display: inline-block;
	float: right;
	margin-top: 5px;
	width: 158px;
}

.loginLogo {
	width: 180px;
}

.userLabel {
	padding: 0 1rem;
	color: #009e4f;
	padding-bottom: 0;
	margin-bottom: 0;
}

/*MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - */
:root {
	--falcon-blue: #009e4f !important;
	--falcon-link-color: #009e4f !important;
	--falcon-btn-bg: #009e4f !important;
	--falcon-navbar-light-brand-color: #009e4f !important;
	--falcon-colored-link-hover-color-primary: #009048 !important;
	--falcon-link-hover-color: #009048 !important;
	--falcon-navbar-vertical-default-link-active-color: #009048 !important;
	--falcon-primary: #009e4f !important;
}

.loginLabel {
	display: inline-block;
	width: 100%;
	text-align: center;
	font-size: 28px;
}

.loginPageFlexContainer {
	padding: 10px 0 !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.dark {
	--falcon-primary: #009e4f !important;
}

.btn-primary {
	--falcon-btn-bg: #009e4f !important;
	--falcon-btn-active-bg: #009e4f !important;
	--falcon-btn-hover-bg: #009048 !important;
	--falcon-btn-hover-border-color: #009048 !important;
}

.lds-ellipsis div {
	background: #009e4f !important;
}

.form-check-input:checked {
	background-color: #009e4f !important;
	border-color: #009e4f !important;
}

.navbar-nav-icons .theme-control-toggle {
	padding-bottom: 6px !important;
	padding: 0 !important;
}

/*MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - MWAN STYLE - */
/*OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD*/
/*.loginPage {
    max-width: 250px;
    margin: 0 auto;
    padding: 150px 0;
}

    .loginPage .forgotPassword {
        margin-top: 15px;
        margin-bottom: 15px;
    }

        .loginPage .forgotPassword a {
            text-decoration: none;
            position: relative;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -ms-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
        }

            .loginPage .forgotPassword a:after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: #0366d6;
                position: absolute;
                bottom: 0;
                left: 0;
                -webkit-transition: all 0.5s;
                -moz-transition: all 0.5s;
                -ms-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
            }

            .loginPage .forgotPassword a:hover {
                color: #808080;
            }

                .loginPage .forgotPassword a:hover:after {
                    background-color: #808080;
                }

    .loginPage h1 {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
    }

    .loginPage form label {
    }

    .loginPage form .inputComponent input {
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;
        padding: 5px;
    }

    .loginPage form .buttonAction {
        display: inline-block;
        width: 100%;
        text-align: right;
    }

        .loginPage form .buttonAction button {
            text-transform: uppercase;
            background-color: #0094ff;
            color: #fff;
            border: 1px solid #0094ff;
            padding: 5px;
            width: 100%;
            text-align: center;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -ms-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
        }

            .loginPage form .buttonAction button:hover {
                background-color: #fff;
                color: #0094ff;
            }
*/
.customForm {
	width: 500px;
	margin: 0 auto;
	padding: 20px 0;
}

.customForm h2 {
	display: inline-block;
	width: 100%;
	margin-bottom: 25px;
}

.customForm .checkBoxListComponent {
	display: inline-block;
	width: 100%;
}

.customForm .checkBoxListComponent label {
	display: inline-block;
	padding-left: 15px;
}

.customForm .checkBoxListComponent input {
	display: inline-block;
	width: auto !important;
	float: left;
	margin-top: 7px;
}

.customForm .inputComponent {
	display: inline-block;
	width: 100%;
}

.customForm .inputComponent input {
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
	padding: 5px;
	border: 1px solid #eee;
}

.customForm .checkBoxComponent {
	cursor: pointer;
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
}

.customForm .checkBoxComponent label,
.customForm .checkBoxComponent input {
	cursor: pointer;
}

.customForm .checkBoxComponent label {
	padding-right: 15px;
}

.customForm .submitSection {
	display: inline-block;
	width: 100%;
	text-align: right;
	padding: 25px 0;
}

.customForm .submitSection .submitButton {
	float: right;
}

.customForm .submitSection .submitButton button {
	background-color: #0094ff;
	color: #fff;
	border: 1px solid #0094ff;
	width: 150px;
	text-align: center;
	padding: 5px 0;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.customForm .submitSection .submitButton button:hover {
	background-color: #fff;
	color: #0094ff;
}

.customForm .submitSection .cancelButton {
	float: right;
	margin-right: 25px;
}

.customForm .submitSection .cancelButton button {
	background-color: #808080;
	color: #fff;
	border: 1px solid #808080;
	width: 150px;
	text-align: center;
	padding: 5px 0;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.customForm .submitSection .cancelButton button:hover {
	background-color: #fff;
	color: #808080;
}

.customForm .alertMessages {
	display: inline-block;
	width: 100%;
}

.customForm .alertMessages .success {
	color: green;
}

/*
    .customTable thead {
        background-color: #000;
        color: #fff;
    }

        .customTable thead tr {
        }

            .customTable thead tr th {
                padding: 5px 10px;
                font-weight: normal;
            }

                .customTable thead tr th.center {
                    text-align: center;
                }

    .customTable tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .customTable tbody tr:nth-child(odd) {
        background-color: #ffffff;
    }

    .customTable tbody tr td {
        padding: 5px 10px;
    }

        .customTable tbody tr td.center {
            text-align: center;
        }

    .customTable button {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        margin-left: 10px;
        padding: 5px 15px;
    }

    .customTable .assignRolesButton {
        background-color: #ff006e;
        color: #fff;
        border: 1px solid #ff006e;
    }

        .customTable .assignRolesButton:hover {
            background-color: transparent;
            color: #ff006e;
        }

    .customTable .editButton {
        background-color: #0094ff;
        color: #fff;
        border: 1px solid #0094ff;
    }

        .customTable .editButton:hover {
            background-color: transparent;
            color: #0094ff;
        }

    .customTable .resetButton {
        background-color: #ff6a00;
        color: #fff;
        border: 1px solid #ff6a00;
    }

        .customTable .resetButton:hover {
            background-color: transparent;
            color: #ff6a00;
        }

    .customTable .disableButton {
        background-color: #ffd800;
        color: #fff;
        border: 1px solid #ffd800;
        width: 80px;
    }

        .customTable .disableButton:hover {
            background-color: transparent;
            color: #ffd800;
        }

    .customTable .enableButton {
        background-color: green;
        color: #fff;
        border: 1px solid green;
        width: 80px;
    }

        .customTable .enableButton:hover {
            background-color: transparent;
            color: green;
        }

    .customTable .deleteButton {
        background-color: #ff0000;
        color: #fff;
        border: 1px solid #ff0000;
    }

        .customTable .deleteButton:hover {
            background-color: transparent;
            color: #ff0000;
        }*/

.customPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #0000004c;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.customPopup .customForm {
	width: 100% !important;
}

.customPopup .popupContent .actionBtns {
	margin-top: 50px;
	display: inline-block;
	width: 100%;
}

.customPopup .popupContent {
	position: absolute;
	width: 500px;
	background-color: #efefef;
	padding: 50px;
	left: calc(50% - 250px);
	top: 50px;
	border-radius: 10px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.customPopup .popupContent p {
	display: inline-block;
	width: 100%;
}

.customPopup .popupContent input {
	width: 100%;
	padding: 5px;
}

.customPopup .popupContent .yesButton {
	float: right;
	width: 120px;
	background-color: green;
	color: #fff;
	border: 1px solid green;
	padding: 5px 0;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.customPopup .popupContent .yesButton:hover {
	background-color: transparent;
	color: green;
}

.customPopup .popupContent .noButton {
	float: right;
	width: 120px;
	background-color: #808080;
	color: #fff;
	border: 1px solid #808080;
	padding: 5px 0;
	margin-right: 15px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.customPopup .popupContent .noButton:hover {
	background-color: transparent;
	color: #808080;
}

.fade-in {
	animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/*
body {
    background-color: #edf2f9 !important;
}

.container {
    max-width: 1140px;
    margin: 0 auto;
}

header {
    padding: 10px 0;
}

    header .logoSection {
    }

        header .logoSection img {
            width: 40px;
        }
        header .logoSection label {
            color: #2c7be5;
            margin-left:10px;
            font-weight:700;
        }*/
